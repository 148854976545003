import type { AppProps } from "next/app";
import { extendTheme } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/provider";
import { ApolloProvider, SuspenseCache } from "@apollo/client";
import { createApolloClient } from "@/util/apollo-client";
import { Suspense, useEffect, useMemo } from "react";
import { Poppins } from "next/font/google";
import FacebookPixel from "@/components/FacebookPixel";
import { PodiumScript } from "@/components/PodiumScript";
import { SanityPopup } from "@/sanity/components/SanityPopup";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Router } from "next/router";

const suspenseCache = new SuspenseCache();

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const theme = extendTheme({
  config,
  fonts: {
    heading: `${poppins.style.fontFamily},sans-serif`,
    body: `${poppins.style.fontFamily},sans-serif`,
  },
});

const postHogKey = "phc_WxQaA36toeciF1AACTIbn3yIyXJPiTotddpgUvSPkea";

export default function App({ Component, pageProps }: AppProps) {
  const client = useMemo(() => {
    return createApolloClient();
  }, []);

  useEffect(() => {
    posthog.init(postHogKey, {
      ui_host: "https://us.posthog.com",
      api_host: "/ingest",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });

    const handleRouteChange = () => posthog?.capture("$pageview");

    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <ApolloProvider client={client} suspenseCache={suspenseCache}>
        <ChakraProvider
          theme={theme}
          colorModeManager={{
            get: () => "light",
            set: () => {},
            type: "localStorage",
          }}
        >
          <style jsx global>{`
            html {
              font-family: ${poppins.style.fontFamily};
            }
          `}</style>
          <Suspense fallback={"Loading..."}>
            <Component {...pageProps} />
          </Suspense>
          <FacebookPixel />
          <PodiumScript />
          <SanityPopup popup={pageProps.popup} />
        </ChakraProvider>
      </ApolloProvider>
    </PostHogProvider>
  );
}
